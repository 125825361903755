import React from "react"
import Layout from "../components/Layout"
import { Content } from "rbx"
import { Helmet } from "react-helmet"

export default ({ data }) => (
  <Layout>
    <Helmet>
      <title>Disclaimer</title>
      <meta name="ROBOTS" content="NOINDEX, NOFOLLOW" />
    </Helmet>
    <Content textAlign="left">
      <h2>Copyright Notice</h2>
      <p>
        Dan Lynch, and danlynch.com.
        Unauthorized use and/or duplication of this material without express and
        written permission from this site&#8217;s author and/or owner is
        strictly prohibited. Excerpts and links may be used, provided that full
        and clear credit is given to Dan Lynch or
        danlynch.com with appropriate and specific direction to the
        original content.
      </p>
      <h2>
        <strong>Terms of Service</strong>:
      </h2>
      <p>
        The following terms and conditions govern all use of the
        https://danlynch.com website and all content, services and products
        available at or through the website, including, but not limited to,
        associated blogs, forums, and websites, taken together, the Website.
        The Website is owned and operated by Dan Lynch. The Website is offered subject to our
        acceptance without modification of all of the terms and conditions
        contained herein and all other operating rules, policies (including,
        without limitation, Dan Lynch Privacy Policy) and
        procedures that may be published from time to time on this Site by Dan Lynch.
        (collectively, the “Agreement”).
      </p>
      <p>
        Please read this Agreement carefully before accessing or using the
        Website. By accessing or using any part of the web site, you agree to
        become bound by the terms and conditions of this agreement. If you do
        not agree to all the terms and conditions of this agreement, then you
        may not access the Website or use any services. If these terms and
        conditions are considered an offer by Dan Lynch,
        acceptance is expressly limited to these terms. The Website is available
        only to individuals who are at least 13 years old.
        <br />
        <strong>
          <br />
          1. Responsibility of Contributors.
        </strong>{" "}
        If you operate a blog, comment on a blog, post material to the Website,
        post links on the Website, or otherwise make (or allow any third party
        to make) material available by means of the Website (any such material,
        “Content”), You are entirely responsible for the content of, and any
        harm resulting from, that Content. That is the case regardless of
        whether the Content in question constitutes text, graphics, an audio
        file, or computer software. By making Content available, you represent
        and warrant that:
      </p>
      <ul>
        <li>
          the downloading, copying and use of the Content will not infringe the
          proprietary rights, including but not limited to the copyright,
          patent, trademark or trade secret rights, of any third party;
        </li>
        <li>
          if your employer has rights to intellectual property you create, you
          have either (i) received permission from your employer to post or make
          available the Content, including but not limited to any software, or
          (ii) secured from your employer a waiver as to all rights in or to the
          Content;
        </li>
        <li>
          you have fully complied with any third-party licenses relating to the
          Content, and have done all things necessary to successfully pass
          through to end users any required terms;
        </li>
        <li>
          the Content does not contain or install any viruses, worms, malware,
          Trojan horses or other harmful or destructive content;
        </li>
        <li>
          the Content is not spam, is not machine- or randomly-generated, and
          does not contain unethical or unwanted commercial content designed to
          drive traffic to third party sites or boost the search engine rankings
          of third party sites, or to further unlawful acts (such as phishing)
          or mislead recipients as to the source of the material (such as
          spoofing);
        </li>
        <li>
          the Content is not libelous or defamatory, (does not contain threats
          or incite violence towards individuals or entities, and does not
          violate the privacy or publicity rights of any third party;
        </li>
        <li>
          your Content is not getting advertised via unwanted electronic
          messages such as spam links on newsgroups, email lists, other blogs
          and web sites, and similar unsolicited promotional methods;
        </li>
        <li>
          your Content is not named in a manner that misleads your readers into
          thinking that you are another person or company. For example, your
          blog or comment’s URL or name is not the name of a person other than
          yourself or company other than your own; and
        </li>
        <li>
          you have, in the case of Content that includes computer code,
          accurately categorized and/or described the type, nature, uses and
          effects of the materials, whether requested to do so by Dan Lynch or otherwise.
        </li>
      </ul>
      <p>
        Without limiting any of those representations or warranties, Dan Lynch
         has the right (though not the obligation) to, in
        Dan Lynch sole discretion (i) refuse or remove any
        content that, in Dan Lynch reasonable opinion, violates
        any Dan Lynch policy or is in any way harmful or
        objectionable, or (ii) terminate or deny access to and use of the
        Website to any individual or entity for any reason, in Dan Lynch sole discretion.
      </p>
      <p>
        <strong>2. Responsibility of Website Visitors.</strong> Dan Lynch has not reviewed, and cannot review, all of the material,
        including computer software, posted to the Website, and cannot therefore
        be responsible for that material’s content, use or effects. By operating
        the Website, Dan Lynch does not represent or imply that
        it endorses the material there posted, or that it believes such material
        to be accurate, useful or non-harmful. You are responsible for taking
        precautions as necessary to protect yourself and your computer systems
        from viruses, worms, Trojan horses, and other harmful or destructive
        content. The Website may contain content that is offensive, indecent, or
        otherwise objectionable, as well as content containing technical
        inaccuracies, typographical mistakes, and other errors. The Website may
        also contain material that violates the privacy or publicity rights, or
        infringes the intellectual property and other proprietary rights, of
        third parties, or the downloading, copying or use of which is subject to
        additional terms and conditions, stated or unstated. Dan Lynch
         disclaims any responsibility for any harm resulting from the
        use by visitors of the Website, or from any downloading by those
        visitors of content there posted.
      </p>
      <p>
        <strong>3. Content Posted on Other Websites.</strong> We have not
        reviewed, and cannot review, all of the material, including computer
        software, made available through the websites and webpages to which
        danlynch.com links, and that link to danlynch.com. Dan Lynch
         does not have any control over those non-danlynch.com
        websites and webpages, and is not responsible for their contents or
        their use. By linking to a non-danlynch.com website or webpage, Dan Lynch
         does not represent or imply that it endorses such
        website or webpage. You are responsible for taking precautions as
        necessary to protect yourself and your computer systems from viruses,
        worms, Trojan horses, and other harmful or destructive content. Dan Lynch
         disclaims any responsibility for any harm
        resulting from your use of non-danlynch.com websites and webpages.
      </p>
      <p>
        <strong>4. Copyright Infringement and DMCA Policy.</strong> As Dan Lynch
         asks others to respect its intellectual property
        rights, it respects the intellectual property rights of others. If you
        believe that material located on or linked to by danlynch.com violates
        your copyright, you are encouraged to notify Dan Lynch
        in accordance with standard Digital Millennium Copyright Act (”DMCA”)
        Policy. Dan Lynch will respond to all such notices,
        including as required or appropriate by removing the infringing material
        or disabling all links to the infringing material. In the case of a
        visitor who may infringe or repeatedly infringes the copyrights or other
        intellectual property rights of Dan Lynch. or others,
        Dan Lynch may, in its discretion, terminate or deny
        access to and use of the Website. In the case of such termination, Dan Lynch
         will have no obligation to provide a refund of any
        amounts previously paid to Dan Lynch.
      </p>
      <p>
        <strong>5. Intellectual Property.</strong> This Agreement does not
        transfer from Dan Lynch to you any Dan Lynch 
        or third party intellectual property, and all right, title and
        interest in and to such property will remain (as between the parties)
        solely with Dan Lynch. Dan Lynch,
        danlynch.com, the “Dan Lynch” logo, and all other
        trademarks, service marks, graphics and logos used in connection with
        danlynch.com, or the Website are trademarks or registered trademarks of
        Dan Lynch's licensors.
        Other trademarks, service marks, graphics and logos used in connection
        with the Website may be the trademarks of other third parties. Your use
        of the Website grants you no right or license to reproduce or otherwise
        use any Dan Lynch or third-party trademarks. You hereby
        grant Dan Lynch an irrevocable, perpetual,
        non-exclusive, transferable, fully paid, worldwide license (with the
        right to sublicense) to (a) use, copy, publish, stream, store, retain,
        publicly perform or display, transmit, scan, reformat, modify, edit,
        frame, translate, excerpt, adapt, create derivative works and distribute
        (through multiple tiers), any Content you post on or in connection with
        the Website or Dan Lynch services or the promotion
        thereof and (b) to use your name, likeness and image for any purpose,
        including commercial or advertising, each of (a) and (b) on or in
        connection with Dan Lynch or the promotion thereof.
      </p>
      <p>
        <strong>6. Changes</strong>. Dan Lynch reserves the
        right, at its sole discretion, to modify or replace any part of this
        Agreement. It is your responsibility to check this Agreement
        periodically for changes. Your continued use of or access to the Website
        following the posting of any changes to this Agreement constitutes
        acceptance of those changes. Dan Lynch may also, in the
        future, offer new services and/or features through the Website
        (including, the release of new tools and resources). Such new features
        and/or services shall be subject to the terms and conditions of this
        Agreement.
      </p>
      <p>
        <strong>7. Termination.</strong> Dan Lynch may terminate
        your access to all or any part of the Website at any time, with or
        without cause, with or without notice, effective immediately. If you
        wish to terminate this Agreement, you may simply discontinue using the
        Website. All provisions of this Agreement which by their nature should
        survive termination shall survive termination, including, without
        limitation, ownership provisions, warranty disclaimers, indemnity and
        limitations of liability.
      </p>
      <p>
        <strong>8. Disclaimer of Warranties.</strong> The Website is provided
        “as is”. Dan Lynch and its suppliers and licensors
        hereby disclaim all warranties of any kind, express or implied,
        including, without limitation, the warranties of merchantability,
        fitness for a particular purpose and non-infringement. Dan Lynch
         nor its suppliers and licensors, makes any warranty that the
        Website will be error free or that access thereto will be continuous or
        uninterrupted. You understand that you download from, or otherwise
        obtain content or services through, the Website at your own discretion
        and risk.
      </p>
      <p>
        <strong>9. Limitation of Liability. </strong>In no event will Dan Lynch
        , or its suppliers or licensors, be liable with
        respect to any subject matter of this agreement under any contract,
        negligence, strict liability or other legal or equitable theory for: (i)
        any special, incidental or consequential damages; (ii) the cost of
        procurement or substitute products or services; (iii) for interruption
        of use or loss or corruption of data; or (iv) for any amounts that
        exceed the fees paid by you to Dan Lynch under this
        agreement during the twelve (12) month period prior to the cause of
        action. Dan Lynch shall have no liability for any
        failure or delay due to matters beyond their reasonable control. The
        foregoing shall not apply to the extent prohibited by applicable law.
      </p>
      <p>
        <strong>10. General Representation and Warranty.</strong> You represent
        and warrant that (i) your use of the Website will be in strict
        accordance with the Dan Lynch Privacy Policy, with this
        Agreement and with all applicable laws and regulations (including
        without limitation any local laws or regulations in your country, state,
        city, or other governmental area, regarding online conduct and
        acceptable content, and including all applicable laws regarding the
        transmission of technical data exported from the United States or the
        country in which you reside) and (ii) your use of the Website will not
        infringe or misappropriate the intellectual property rights of any third
        party.
      </p>
      <p>
        <strong>11. Indemnification.</strong> You agree to indemnify and hold
        harmless Dan Lynch, its contractors, and its licensors,
        and their respective directors, officers, employees and agents from and
        against any and all claims and expenses, including attorneys’ fees,
        arising out of your use of the Website, including but not limited to out
        of your violation this Agreement.
      </p>
    </Content>
  </Layout>
)
